import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import SendMessage from './SendMessage';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

// Configuração do tema Material UI
const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
});

// Componente de rota protegida
const ProtectedRoute = ({ children }) => {
    // Verifica se o token existe
    const token = localStorage.getItem('token');

    // Verifica se a data de expiração ainda é válida
    const dataExpiracao = localStorage.getItem('dataExpiracao');
    const isExpired = dataExpiracao ? new Date() > new Date(dataExpiracao) : true;

    // Verifica se a data armazenada é a data atual
    const dateNow = localStorage.getItem('dateNow');
    const hoje = new Date().toDateString(); // Obtém apenas a data (sem hora)
    const dateNowDiferente = dateNow ? dateNow !== hoje : true;

    // Se não houver token, a data estiver expirada ou a data atual for diferente da armazenada, redireciona para o login
    if (!token || isExpired || dateNowDiferente) {
        // Limpa o localStorage se estiver expirado ou a data for diferente
        if (isExpired || dateNowDiferente) {
            localStorage.clear();
        }
        return <Navigate to="/" replace />;
    }

    // Se tudo estiver ok, renderiza o componente filho
    return children;
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Routes>
                    {/* Rota de login */}
                    <Route path="/" element={<Login />} />

                    {/* Rota de envio de mensagens protegida */}
                    <Route
                        path="/send-message"
                        element={
                            <ProtectedRoute>
                                <SendMessage />
                            </ProtectedRoute>
                        }
                    />

                    {/* Rota para redirecionamento em caso de URLs desconhecidas */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
