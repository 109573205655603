import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Pagination,
  Modal,
  Paper
} from '@mui/material';

const SendMessage = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const [message, setMessage] = useState('');
  const [numbers, setNumbers] = useState('');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [loadingContacts, setLoadingContacts] = useState(true);
  const [responseMessage, setResponseMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [source, setSource] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [selectedSource, setSelectedSource] = useState('');
  const [media, setMedia] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);

  const instance = localStorage.getItem('instance');
  const apiKey = localStorage.getItem('evolution_token');
  const itemsPerPage = 100;

  // File handling
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSizeInMB = 60;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (file.size > maxSizeInBytes) {
        setErrorMessage(`O arquivo não pode exceder ${maxSizeInMB} MB.`);
        setMedia(null);
      } else {
        setErrorMessage('');
        setMedia(file);
      }
    }
  };

  // Connection check
  const checkConnection = async () => {
    try {
      const response = await axios.get(
        `https://evolution.acelera-apps.com.br/instance/connect/${instance}`,
        { headers: { apikey: apiKey } }
      );

      if (response.data.pairingCode || response.data.base64) {
        setIsConnected(false);
        setQrCode(response.data.base64);
      } else {
        setIsConnected(true);
      }
    } catch (error) {
      console.error('Erro ao verificar conexão:', error);
      setIsConnected(false);
    }
  };

  // Phone number formatting
  const formatPhone = (phone) => {
    const sanitized = phone.replace(/\D/g, '');

    if (sanitized.length === 11) return sanitized;
    if (sanitized.length === 10) return `${sanitized.slice(0, 2)}9${sanitized.slice(2)}`;
    if (sanitized.length === 13) return sanitized.slice(2);
    if (sanitized.length === 12) {
      const formatted = sanitized.slice(2);
      return `${formatted.slice(0, 2)}9${formatted.slice(2)}`;
    }

    console.warn(`Número inválido: ${phone}`);
    return null;
  };

  // Load contacts
  const loadContacts = async () => {
    setLoadingContacts(true);
    try {
      const response = await axios.post(
        `https://evolution.acelera-apps.com.br/chat/findContacts/${instance}`,
        { where: {} },
        {
          headers: {
            'Content-Type': 'application/json',
            apikey: apiKey,
          },
        }
      );

      const existingContacts = JSON.parse(localStorage.getItem('contatos_whatsapp')) || {};

      const whatsAppContacts = response.data.map((contact) => {
        const number = formatPhone(contact.remoteJid.split('@')[0]);
        const existing = existingContacts[number] || {};

        return {
          id: existing.id || contact.id,
          name: existing.name || contact.pushName || 'Desconhecido',
          number,
          lastSent: existing.lastSent || 'Nunca enviado',
          source: existing.source || 'WHATSAPP',
        };
      });

      const updatedContacts = { ...existingContacts };
      whatsAppContacts.forEach((contact) => {
        updatedContacts[contact.number] = contact;
      });

      localStorage.setItem('contatos_whatsapp', JSON.stringify(updatedContacts));
      setContacts(Object.values(updatedContacts));
      setFilteredContacts(Object.values(updatedContacts));
    } catch (error) {
      console.error('Erro ao carregar contatos:', error);
    } finally {
      setLoadingContacts(false);
    }
  };

  // Search and filter functions
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterContacts(query, selectedSource);
  };

  const handleSourceChange = (e) => {
    const source = e.target.value;
    setSelectedSource(source);
    filterContacts(searchQuery, source);
  };

  const filterContacts = (query, source) => {
    setFilteredContacts(
      contacts.filter((contact) => {
        const name = (contact.name || 'Desconhecido').toString().toLowerCase();
        const matchesName = name.includes(query);
        const matchesSource = !source || contact.source === source;
        return matchesName && matchesSource;
      })
    );
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setSearchQuery('');
    setSelectedSource('');
    setFilteredContacts(contacts);
    setCurrentPage(1);
  };

  // Contact selection
  const handleSelectContact = (number) => {
    setSelectedContacts((prev) => {
      if (prev.includes(number)) {
        return prev.filter(item => item !== number);
      }
      if (prev.length >= 100) {
        alert('Você só pode selecionar no máximo 100 contatos por vez.');
        return prev;
      }
      return [...prev, number];
    });
  };

  const handleSelectAllCurrentPage = () => {
    setSelectedContacts((prev) => {
      const currentNumbers = paginatedContacts.map(contact => contact.number);
      const remainingSlots = 100 - prev.length;

      if (remainingSlots <= 0) {
        alert('Você só pode selecionar no máximo 100 contatos por vez.');
        return prev;
      }

      const numbersToAdd = currentNumbers
        .filter(number => !prev.includes(number))
        .slice(0, remainingSlots);

      return [...prev, ...numbersToAdd];
    });
  };

  const handleDeselectAll = () => {
    setSelectedContacts([]);
  };

  // Message sending
  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!isConnected) {
      setResponseMessage('Instância desconectada. Escaneie o QR Code para conectar.');
      return;
    }

    const allNumbers = [
      ...selectedContacts,
      ...numbers.split(',').map(num => num.trim()).filter(Boolean)
    ];

    if (allNumbers.length === 0) {
      setResponseMessage('Por favor, forneça números ou selecione contatos para envio.');
      return;
    }

    try {
      setIsSending(true);

      const formData = new FormData();
      formData.append('Mensagem', message);
      formData.append('Numeros', allNumbers.join(','));
      formData.append('instancia', instance);
      formData.append('apikey', apiKey);
      formData.append('url', 'https://evolution.acelera-apps.com.br');

      if (media) {
        formData.append('media', media);
        formData.append('envio', 'imagem');
      } else {
        formData.append('envio', 'texto');
      }

      const response = await axios.request({
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://apps-n8n.gwsv2b.easypanel.host/webhook/whatsapp',
        data: formData,
      });

      const now = new Date().toLocaleString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });

      const existingContacts = JSON.parse(localStorage.getItem('contatos_whatsapp')) || {};
      allNumbers.forEach(number => {
        if (existingContacts[number]) {
          existingContacts[number].lastSent = now;
        }
      });

      localStorage.setItem('contatos_whatsapp', JSON.stringify(existingContacts));
      setContacts(Object.values(existingContacts));
      setFilteredContacts(Object.values(existingContacts));
      setSelectedContacts([]);
      setSearchQuery('');
      setResponseMessage('MENSAGEN(S) ENVIADA(S) PARA O SERVIDOR COM SUCESSO, VOCÊ JÁ PODE FECHAR O NAVEGADOR! O TEMPO DE DISPARIO É DE 30 SEGUNDOS A 2:30 MINUTOS POR MENSAGEM!');
    } catch (error) {
      setResponseMessage(`Erro ao enviar mensagem: ${error.message}`);
    } finally {
      setIsSending(false);
    }
  };

  // Import contacts
  const handleImportContacts = () => {
    if (!file || !source) {
      alert('Por favor, selecione um arquivo e informe a origem.');
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const workbook = XLSX.read(event.target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const headers = data[0].map(header => header.toLowerCase().trim());

        if (!headers.includes('nome') || !headers.includes('número')) {
          throw new Error('O arquivo deve conter as colunas "Nome" e "Número".');
        }

        const existingContacts = JSON.parse(localStorage.getItem('contatos_whatsapp')) || {};

        const newContacts = data.slice(1)
          .map(row => {
            const name = row[headers.indexOf('nome')];
            const number = row[headers.indexOf('número')];

            if (!number) return null;

            const formattedNumber = formatPhone(number.toString());
            if (!formattedNumber) return null;

            return {
              id: existingContacts[formattedNumber]?.id || `${formattedNumber}-${Date.now()}`,
              name: name || 'Desconhecido',
              number: formattedNumber,
              lastSent: existingContacts[formattedNumber]?.lastSent || 'Nunca enviado',
              source: source.toUpperCase(),
            };
          })
          .filter(Boolean);

        newContacts.forEach(contact => {
          existingContacts[contact.number] = contact;
        });

        localStorage.setItem('contatos_whatsapp', JSON.stringify(existingContacts));
        setContacts(Object.values(existingContacts));
        setFilteredContacts(Object.values(existingContacts));

        alert(`Importação concluída com sucesso! ${newContacts.length} contatos foram importados.`);
        setModalOpen(false);
      } catch (error) {
        console.error('Erro durante a importação:', error);
        alert(`Erro ao importar contatos: ${error.message}`);
      }
    };

    reader.onerror = (error) => {
      console.error('Erro ao ler o arquivo:', error);
      alert('Erro ao ler o arquivo. Por favor, tente novamente.');
    };

    reader.readAsBinaryString(file);
  };

  // Handle file upload
  const handleFileUpload = (e) => setFile(e.target.files[0]);

  // Clear contacts
  const handleClearContacts = () => {
    if (window.confirm('Tem certeza de que deseja limpar todos os contatos salvos?')) {
      localStorage.removeItem('contatos_whatsapp');
      setContacts([]);
      setFilteredContacts([]);
      alert('Todos os contatos foram removidos. Recarregando contatos do WhatsApp...');
      loadContacts();
    }
  };

  // Modal controls
  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handleOpenTemplateModal = () => setIsTemplateModalOpen(true);
  const handleCloseTemplateModal = () => setIsTemplateModalOpen(false);

  // Pagination
  const handlePageChange = (event, value) => setCurrentPage(value);
  const paginatedContacts = filteredContacts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Initial load
  useEffect(() => {
    checkConnection();
    loadContacts();
  }, []);

  const handleReload = () => {
    window.location.reload(); // Recarrega a página
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        p: { xs: 1, sm: 2 },
        width: '100%',
        margin: 0
      }}
    >
      {isConnected ? (
        <>
          {/* Message Form */}
          <Grid item xs={12} sm={12} md={8} sx={{ mx: 'auto' }}>
            <Card elevation={3}>
              <CardHeader
                title={
                  <Typography variant="h3" sx={{
                    fontSize: { xs: '1.2rem', sm: '1.5rem' },
                    textAlign: 'center',
                    color: '#1976d2'
                  }}>
                    ACELERA ZAP <small>v.2.0</small>
                  </Typography>
                }
                subheader={
                  <Typography sx={{
                    fontSize: { xs: '0.875rem', sm: '1rem' },
                    textAlign: 'center'
                  }}>
                    <small>Preencha as informações para enviar sua mensagem.
                      Ela será transmitida automaticamente para todos os clientes selecionados por meio do servidor,
                      sem a necessidade de manter o navegador aberto até a conclusão do envio. Além disso, as mensagens serão
                      levemente modificadas por uma IA para reduzir a chance de serem identificadas como spam pelo WhatsApp.</small>
                  </Typography>
                }
              />
              <CardContent sx={{ p: { xs: 1, sm: 2 } }}>
                <Box sx={{ textAlign: 'right', marginTop: 2, marginBottom: "10px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleReload}
                  >
                    Limpar Formulário
                  </Button>
                </Box>
                <form onSubmit={handleSendMessage}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <TextField
                      label="Mensagem"
                      multiline
                      rows={4}
                      fullWidth
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                    <TextField
                      label="Números (separados por vírgula)"
                      fullWidth
                      value={numbers}
                      onChange={(e) => setNumbers(e.target.value)}
                      placeholder="Digite os números manualmente ou selecione nos contatos!"
                      size="small"
                    />
                    <TextField
                      type="file"
                      onChange={handleFileChange}
                      label="Arquivo de Vídeo ou Foto de até 60 megas"
                      fullWidth
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ accept: 'image/*,video/*' }}
                    />
                    {errorMessage && (
                      <Typography color="error" variant="body2">
                        {errorMessage}
                      </Typography>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={isSending}
                      sx={{ mt: 2 }}
                    >
                      {isSending ? <CircularProgress size={24} /> : 'Enviar Mensagem'}
                    </Button>
                  </Box>
                </form>
                {responseMessage && (
                  <Typography
                    variant="body2"
                    sx={{
                      mt: 2,
                      textAlign: 'center',
                      color: responseMessage.startsWith('Erro') ? 'red' : 'green'
                    }}
                  >
                    {responseMessage}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Contacts Table */}
          <Grid item xs={12} sm={12} md={8} sx={{ mx: 'auto' }}>
            <Card elevation={3}>
              <CardHeader
                title={
                  <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    gap: 1,
                    alignItems: { xs: 'stretch', sm: 'center' },
                    '& > button': {
                      width: { xs: '100%', sm: 'auto' }
                    }
                  }}>
                    <Typography variant="h6" sx={{ flex: 1 }}>Contatos Disponíveis</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenModal}
                      size="small"
                    >
                      Importar Excel
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleOpenTemplateModal}
                      size="small"
                    >
                      Ver Modelo
                    </Button>
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={handleClearContacts}
                      size="small"
                    >
                      Deletar
                    </Button>
                  </Box>
                }
              />
              <CardContent sx={{ p: { xs: 1, sm: 2 } }}>
                <Box sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 1,
                  mb: 2
                }}>
                  <TextField
                    label="Buscar por Nome"
                    fullWidth
                    value={searchQuery}
                    onChange={handleSearch}
                    size="small"
                  />
                  <TextField
                    select
                    label="Origem"
                    value={selectedSource}
                    onChange={handleSourceChange}
                    fullWidth
                    size="small"
                    SelectProps={{ native: true }}
                    InputLabelProps={{
                      shrink: true // Adicionado para fixar o label
                    }}
                  >
                    <option value="">Todas</option>
                    {[...new Set(contacts.map(c => c.source))].map(source => (
                      <option key={source} value={source}>{source}</option>
                    ))}
                  </TextField>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={clearFilters}
                    fullWidth
                    size="small"
                  >
                    Limpar Filtros
                  </Button>
                </Box>

                <Box sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 1,
                  mb: 2
                }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSelectAllCurrentPage}
                    fullWidth
                    size="small"
                    disabled={paginatedContacts.every(contact => selectedContacts.includes(contact.number))}
                  >
                    Selecionar Página
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={handleDeselectAll}
                    fullWidth
                    size="small"
                    disabled={selectedContacts.length === 0}
                  >
                    Remover Seleção
                  </Button>
                </Box>

                {loadingContacts ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <TableContainer sx={{
                    overflow: 'auto',
                    maxHeight: { xs: '50vh', sm: 'none' }
                  }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">Sel.</TableCell>
                          <TableCell>Nome</TableCell>
                          <TableCell>Número</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Origem</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paginatedContacts.map((contact) => (
                          <TableRow key={contact.id}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedContacts.includes(contact.number)}
                                onChange={() => handleSelectContact(contact.number)}
                                disabled={!selectedContacts.includes(contact.number) && selectedContacts.length >= 50}
                              />
                            </TableCell>
                            <TableCell>{contact.name}</TableCell>
                            <TableCell>{contact.number}</TableCell>
                            <TableCell>{contact.lastSent}</TableCell>
                            <TableCell>{contact.source}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 2,
                  '& .MuiPagination-ul': {
                    flexWrap: 'nowrap'
                  }
                }}>
                  <Pagination
                    count={Math.ceil(filteredContacts.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    size="small"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </>
      ) : (
        <Grid item xs={12} sm={8} md={6} sx={{ mx: 'auto' }}>
          <Card elevation={3}>
            <CardHeader
              title="Instância Desconectada"
              subheader="Escaneie o QR Code para conectar"
              sx={{ textAlign: 'center', color: '#d32f2f' }}
            />
            <CardContent sx={{ textAlign: 'center' }}>
              {qrCode ? (
                <img src={qrCode} alt="QR Code" style={{ maxWidth: '100%' }} />
              ) : (
                <CircularProgress />
              )}
              <Typography variant="body2" sx={{ mt: 2 }}>
                Use o aplicativo para escanear o código e conectar a instância.
              </Typography>
            </CardContent>
            <Button onClick={handleReload} variant="contained" color="primary" fullWidth>
              Clique aqui após escanear o código QR.
            </Button>
          </Card>
        </Grid>
      )}

      {/* Import Modal */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 400 },
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 3,
          borderRadius: 2
        }}>
          <Typography variant="h6" gutterBottom>
            Importar Contatos
          </Typography>
          <TextField
            fullWidth
            type="file"
            accept=".xlsx,.xls"
            onChange={handleFileUpload}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Origem dos Contatos"
            value={source}
            onChange={(e) => setSource(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleImportContacts}
          >
            Importar
          </Button>
        </Box>
      </Modal>

      {/* Template Modal */}
      <Modal open={isTemplateModalOpen} onClose={handleCloseTemplateModal}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '90%', sm: 400 },
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 3,
          borderRadius: 2
        }}>
          <Typography variant="h6" gutterBottom>
            Modelo de Arquivo Excel
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            O arquivo deve conter as seguintes colunas:
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Número</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>João da Silva</TableCell>
                  <TableCell>51999091255</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Maria Souza</TableCell>
                  <TableCell>51987012345</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="contained" onClick={handleCloseTemplateModal}>
              Fechar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Grid>
  );
};

export default SendMessage;