import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PocketBase from 'pocketbase';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    TextField,
    Typography,
    Grid,
} from '@mui/material';

// URL do seu PocketBase
const pb = new PocketBase('https://apps-pocketbase.gwsv2b.easypanel.host/');

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            // Autentica o usuário no PocketBase
            const authData = await pb.collection('users').authWithPassword(email, password);
            console.log(authData);

            // Verifica se a conta está ativa
            if (authData.record.isActive === false) {
                setMessage('Conta expirada. Entre em contato com o suporte.');
                return; // Interrompe a execução da função
            }

            // Verifica se a data de expiração já passou
            const dataAtual = new Date();
            const dataExpiracao = new Date(authData.record.dataExpiracao);

            if (dataAtual > dataExpiracao) {
                setMessage('Conta expirada. Entre em contato com o suporte.');
                return; // Interrompe a execução da função
            }

            // Se a conta estiver ativa e não expirada, prossegue com o login
            // Armazena os dados relevantes no LocalStorage
            localStorage.setItem('token', authData.token); // Token JWT
            localStorage.setItem('evolution_token', authData.record.evolution_token); // Token de evolução
            localStorage.setItem('instance', authData.record.instance); // Instância
            localStorage.setItem('name', authData.record.name); // Nome do usuário
            localStorage.setItem('dataExpiracao', authData.record.dataExpiracao); // Data de Expiracao
            localStorage.setItem('dateNow', new Date().toDateString());
            setMessage(`Login realizado com sucesso! Bem-vindo, ${authData.record.name}.`);
            // Redireciona para a tela de envio de mensagens
            navigate('/send-message');
        } catch (error) {
            setMessage('Erro ao realizar login: Verifique sua senha');
        }
    };



    return (
        <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh', backgroundColor: '#f5f5f5' }}
        >
            <Grid item xs={12} sm={8} md={4}>
                <Card elevation={3}>
                    <CardHeader
                        title="Login"
                        subheader="Entre com suas credenciais"
                        style={{ textAlign: 'center', color: '#1976d2' }}
                    />
                    <CardContent>
                        <form onSubmit={handleLogin}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <TextField
                                    label="E-mail"
                                    variant="outlined"
                                    fullWidth
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <TextField
                                    label="Senha"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                >
                                    Entrar
                                </Button>
                            </Box>
                        </form>
                        {message && (
                            <Typography
                                variant="body2"
                                style={{
                                    marginTop: 16,
                                    textAlign: 'center',
                                    color: message.startsWith('Erro') ? 'red' : 'green',
                                }}
                            >
                                {message}
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Login;
